import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import logo from './logo-removebg-preview.png'; // Make sure the image is in your `src` folder

const UnderDevelopment = () => {
    return (
        <div className="flex items-center justify-center h-screen" style={{ backgroundColor: '#367ba0' }}>
            <div className="text-center">
                {/* Logo Section */}
                <div className="bg-white rounded-full p-4 mx-auto mb-6 shadow-lg inline-block">
                    <img
                        src={logo}
                        alt="WorknConnect Logo"
                        className="w-24"
                    />
                </div>

                {/* Title and Message */}
                <h1 className="text-4xl font-bold mb-4" style={{ color: '#ffffff' }}>
                    Welcome to WorknConnect
                </h1>
                <p className="text-white text-lg mb-6">
                    🚀 Our website is under development. We’re working hard to launch something amazing!
                </p>

                {/* Spinner */}
                <div className="flex justify-center mb-6">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-4" style={{ borderColor: '#ffffff' }}></div>
                </div>
                <p className="text-white mb-8">Stay tuned for updates. Launching soon!</p>

                {/* Social Media Icons */}
                <div className="flex justify-center space-x-6">
                    <a
                        href="https://www.instagram.com/worknconnect/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:scale-110 transition transform text-3xl"
                        style={{ color: '#ffffff' }}
                    >
                        <FaInstagram />
                    </a>
                    <a
                        href="https://www.facebook.com/profile.php?id=61563614937620&mibextid=LQQJ4d"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:scale-110 transition transform text-3xl"
                        style={{ color: '#ffffff' }}
                    >
                        <FaFacebook />
                    </a>
                    <a
                        href="https://www.linkedin.com/in/worknconnect-innovators-6b009a321/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:scale-110 transition transform text-3xl"
                        style={{ color: '#ffffff' }}
                    >
                        <FaLinkedin />
                    </a>
                    <a
                        href="https://x.com/wncinnovators?s=21"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:scale-110 transition transform text-3xl"
                        style={{ color: '#ffffff' }}
                    >
                        <FaTwitter />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default UnderDevelopment;
